<section class="md:py-16 py-5 break-words hyphens-auto">
  <div
    class="rounded-tl-3xl text-center rounded-br-3xl flex flex-col justify-center mx-5 md:mx-auto md:w-[40%] 2xl:w-[30%] bg-gray-secondary-100 shadow-xl"
  >
    <div class="mx-auto">
      <img
        class="py-10 pl-8 pr-9"
        src="../../../assets/images/coFounder.png"
        alt="img"
      />
    </div>
    <div class="md:w-[50%] mx-auto">
      <h4 class="text-bold text-turquoise-duckfortiery mt-5">Pamela Secaira</h4>
      <h6>{{'about-us-page.pebra-team.manager' | translate}}</h6>
    </div>
    <div class="py-5 mx-auto mb-10 flex justify-center gap-5">
      <a
        href="https://www.facebook.com/profile.php?id=61558889392837"
        target="_blank"
      >
        <img src="../../../assets/icons/facebook-aboutus.svg" alt="icons" />
      </a>
      <a href="https://www.instagram.com/pebra.clean.more/" target="_blank">
        <img src="../../../assets/icons/instagram-aboutus.svg" alt="icons" />
      </a>
      <a
        href="https://www.linkedin.com/company/pebra-clean-more/?viewAsMember=true"
        target="_blank"
      >
        <img src="../../../assets/icons/linkedin-aboutus.svg" alt="icons" />
      </a>
    </div>
  </div>
</section>
